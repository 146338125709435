import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import userApi from 'apis/userApi';
import LotteryChanel from 'components/LotteryChanel/LotteryChanel';
import './DetailPage.scss';
import { useTranslation } from 'react-i18next';
function DefailPage() {
    const location = useLocation();
    const { t } = useTranslation();
    const queryEvent: any = queryString.parse(location.search);
    const { id } = queryEvent;

    const [currentIssue, setCurrentIssue] = useState<any>(null);
    const [issueHistoryList, setIssueHistoryList] = useState<any[]>([]);
    const [viewDetail, setViewDetail] = useState(true);

    useEffect(() => {
        handleEventResize();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleEventResize);

        return () => {
            window.removeEventListener('resize', handleEventResize);
        };
    }, []);

    useEffect(() => {
        const payload = new URLSearchParams({ flag: 'LotteryListBean', lotteryId: `${id}`, num: '14' });
        const getLotteryDetail = async () => {
            try {
                const { data, status } = await userApi.getListBeanDetail(payload);
                if (status === 200) {
                    const lotteryBean = data.data[0];
                    if (lotteryBean) {
                        setCurrentIssue(lotteryBean.cursitem);
                        setIssueHistoryList(lotteryBean.history);
                        // updateListIssue(lotteryBean.history);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLotteryDetail();
    }, []);

    const handleEventResize = () => {
        if (window.innerWidth < 600) {
            setViewDetail(false);
        } else {
            setViewDetail(true);
        }
    };

    return (
        <div className="detailPage">
            <div className="detailHeader">
                <h4 className="detaiTitle">{t(`${id}`)}</h4>
            </div>
            <div className="detailBody">
                {currentIssue && (
                    <LotteryChanel curentIssueProps={currentIssue} historyList={issueHistoryList} orderHistory={14} detail={viewDetail} />
                )}
            </div>
        </div>
    );
}

export default DefailPage;
